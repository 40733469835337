@tailwind base;
@tailwind components;
@tailwind utilities;

.home-page {
    background-image: url('./assets/images/backgound/bg.jpg');
    background-size: cover;
    background-position: center;
    background-color: black;
    position: relative;
    height: 80vh;
    width: 100%;
    /* padding: 20px; */
    box-sizing: border-box;
    background-repeat: no-repeat;

}

#navbar {
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;

}

body {
    background-color: black;
}

.align-slider .slick-track {
    display: flex;
    align-items: center;
}

/* Responsie of sketelen in Caroucel Movie */
@media (max-width: 500px) {
    .xxx {
        position: relative;
        left: 8px;
        padding: 20px;
        width: 156px;
        height: 234px;
    }
}

@media (max-width: 590px) {
    .xxx {
        position: relative;
        left: 8px;
        padding: 20px;
        width: 221px;
        height: 331px;
    }
}

@media (max-width: 480px) {
    .xxx {
        position: relative;
        left: 8px;
        padding: 20px;
        width: 156px;
        height: 234px;
    }

    .section-bg {
        /* background-image: url('./assets/images/backgound/bg.jpg');
        background-size: cover;
        background-position: center;  */
        /* position: relative;
        height: 75vh; */
        /* width: 100%; 
        padding: 20px;
        box-sizing: border-box; */
    }
}

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.my-float {
    margin-top: 16px;
}